<template>
    <div class="base-page">
        <div class="query-box">
            <el-form :inline="true" :model="query_form"  ref="query_form" class="demo-form-inline">
<!--                <div>-->
<!--                    <div>-->
<!--                        <span >查询条件</span>-->
<!--                        <span style="color: red;font-size: 12px">{{msg}}</span>-->
<!--                    </div>-->

<!--                </div>-->

                <el-form-item label="收费日期范围：" prop="date_between">
                    <el-date-picker
                            size="mini"
                            v-model="query_form.charge_date_between"
                            type="daterange"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            @change="chargeDateChang"
                    >
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="查询对象：" prop="type" style="margin-left: 15px">
                    <el-select v-model="query_form.type" placeholder="请选择"  size="mini" style="width: 100px" @change="typeChange">
                        <el-option
                                v-for="item in type_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <template v-if="query_form.type==1">
                    <el-form-item  prop="order_channel" >
                        <el-select v-model="query_form.select_build_id" placeholder="请选择楼宇"  size="mini" style="width: 300px"     @change="buildChange">
                            <el-option
                                    key="0"
                                    label="全部"
                                        value="0">
                            </el-option>
                            <el-option
                                    v-for="item in query_form.buildArr"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </template>

                <template v-if="query_form.type==2">
                    <el-form-item  prop="order_channel">
                        <el-select v-model="query_form.select_unit_id" placeholder="请选择单元"  size="mini" style="width: 300px" @change="unitChange">
                            <el-option
                                    key="0"
                                    label="全部"
                                    value="0">
                            </el-option>
                            <el-option
                                    v-for="item in query_form.unitArr"
                                    :key="item.unit_id"
                                    :label="item.all_name"
                                    :value="item.unit_id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </template>

                <div style="flex:1">

                    <template v-if="query_form.month_between.length !=0">
                        <el-form-item label="费用期间范围：" prop="date_between">

                            <span style="color: red;font-size: 12px">{{msg}}</span> &nbsp;&nbsp;
                            <el-date-picker
                                    size="mini"
                                    v-model="query_form.month_between"
                                    type="monthrange"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="开始月份"
                                    end-placeholder="结束月份"
                                    @change="monthChang"
                            >
                            </el-date-picker>

                        </el-form-item>

                    </template>



                    <el-form-item>
                        <el-button size="mini" type="primary" @click="query">查询</el-button>
                    </el-form-item>
                </div>



            </el-form>


        </div >


        <div class="base-body" id="base-body">

            <div class="base-table" >

                <el-table
                        :data="dataList"
                        border
                        :height="tableHeight"
                        v-loading="loading"
                >
                    <el-table-column
                            v-for="item in fixed_columns"
                            :key="item.prop"
                            :prop="item.prop"
                            :label="item.label"
                            :width="item.width"
                            :formatter="item.formatter"
                            :fixed = "item.fixed"
                    >
                    </el-table-column>

                    <el-table-column :label="float_title">
                        <el-table-column
                                v-for="item in float_columns"
                                :key="item.prop"
                                :prop="item.prop"
                                :label="item.label"
                                :width="item.width"
                                :formatter="item.formatter"
                                :fixed = "item.fixed"
                        >
                        </el-table-column>
                    </el-table-column>

                </el-table>
            </div>
        </div>


    </div>




</template>

<script>

    export default {
        name: "main_page",
        props:['upPageParams'],
        components:{

        },
        data() {
            return {

                loading:false,

                query_form:{
                    //收费日期范围
                    charge_date_between:[],
                    charge_start_date:'',
                    charge_end_date:'',

                    //查询对象
                    type:'1',//默认按楼宇
                    buildArr:[],//楼宇数据
                    unitArr:[],//单元数据
                    select_build_id:"0",//选择的楼宇，默认全部
                    select_unit_id:"0",//选择的单元，默认全部

                    //费用期间
                    month_between:[],
                    start_month:'',
                    end_month:''

                },


                type_options: [ {
                    value: '1',
                    label: '按楼宇'
                }, {
                    value: '2',
                    label: '按单元'
                }],


                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);

                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                tableHeight: 0,
                fixed_columns: [

                    //
                    // {
                    //     label: '收费人',
                    //     prop: 'account_name',
                    //     width: 100
                    // },
                ],
                float_columns:[],
                float_title:'',

                max_cost_month_start : '',
                max_cost_month_end : '',

                dataList:[],
                msg:''

            }
        },
        created() {
            this.getBuildList()
            this.getUnitList()
        },
        mounted() {

            this.$nextTick(function() {
                this.tableHeight = document.getElementById("base-body").offsetHeight;
                // this.query()
            })
        },
        methods:{

            //获取楼宇
            getBuildList(){
                let params = {
                    residence_id:this.upPageParams.commiunity.id
                }
                this._getApi('/wy/residenceBuild/getList',params).then((res)=>{
                    if (res.code ==1){

                        this.query_form.buildArr = res.data.list

                    }

                })
            },
            //获取单元列表
            getUnitList(){
                let params = {
                    residence_id:this.upPageParams.commiunity.id
                }
                this._getApi('/wy/residenceBuildUnit/getBuildUnitListByResidence',params).then((res)=>{
                    if (res.code ==1){
                        this.query_form.unitArr = res.data
                    }
                })
            },

            //收费日期变化
            chargeDateChang(){
                if (this.query_form.charge_date_between){
                    this.query_form.charge_start_date = this.dayjs(this.query_form.charge_date_between[0]).format("YYYY-MM-DD")
                    this.query_form.charge_end_date = this.dayjs(this.query_form.charge_date_between[1]).format("YYYY-MM-DD")
                }else {
                    this.query_form.charge_start_date = ''
                    this.query_form.charge_end_date  = ''
                }

                //初始化费用期间
                this.query_form.month_between  = []
                this.query_form.start_month=''
                this.query_form.end_month   =''
            },
            //费用期间变化
            monthChang(){
                if (this.query_form.month_between){
                    this.query_form.start_month = this.dayjs(this.query_form.month_between[0]).format("YYYY-MM")
                    this.query_form.end_month = this.dayjs(this.query_form.month_between[1]).format("YYYY-MM")
                }else {
                    this.query_form.start_month = ''
                    this.query_form.end_month  = ''
                }

            },
            typeChange(){
                //初始化费用期间
                this.query_form.month_between  = []
                this.query_form.start_month=''
                this.query_form.end_month   =''
            },
            buildChange(){
                //初始化费用期间
                this.query_form.month_between  = []
                this.query_form.start_month=''
                this.query_form.end_month   =''
            },
            unitChange(){
                //初始化费用期间
                this.query_form.month_between  = []
                this.query_form.start_month=''
                this.query_form.end_month   =''
            },

            //查询
            query(){
                if (this.query_form.charge_start_date=='' || this.query_form.charge_end_date==''){
                    this.$alert('请选择收费日期范围', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    }).then(() => {

                    }).catch(() => {

                    });
                    return false
                }
                let params = {
                    residence_id:this.upPageParams.commiunity.id,
                    type:this.query_form.type,
                    charge_start_date: this.query_form.charge_start_date,
                    charge_end_date: this.query_form.charge_end_date,

                    cost_month_start:this.query_form.start_month,
                    cost_month_end:this.query_form.end_month,
                }
                //楼栋或者单元id
                if (params.type==1){
                    params.id = this.query_form.select_build_id
                }else {
                    params.id = this.query_form.select_unit_id
                }



                this.loading = true
                this._postApi('/wy/data/chargeSummary/getList', params).then((res) => {
                    this.loading = false

                    if (res.code == 1) {

                        let fixed_head = res.data.fixed_head
                        this.fixed_columns=[];
                        if (fixed_head.length >0){
                            for (var i=0;i<fixed_head.length;i++){
                                this.fixed_columns.push({
                                    label:fixed_head[i]['title'],
                                    prop:fixed_head[i]['field']

                                })
                            }
                        }


                        let float_head = res.data.float_head
                        this.float_columns=[];
                        if (float_head.length>0){
                            for (var b=0;b<float_head.length;b++){
                                this.float_columns.push({
                                    label:float_head[b]['title'],
                                    prop:float_head[b]['field']

                                })
                            }
                        }
                        if (res.data.list.length!=0){
                            if (this.query_form.start_month == ''){
                                this.query_form.start_month = res.data.cost_month_start;
                                this.query_form.end_month = res.data.cost_month_end;
                                this.query_form.month_between = [res.data.cost_month_start,res.data.cost_month_end]

                            }
                            this.max_cost_month_start = res.data.cost_month_start
                            this.max_cost_month_end = res.data.cost_month_end

                            this.float_title = this.query_form.charge_start_date+'至'+this.query_form.charge_end_date+'日 收'
                                +this.query_form.start_month+'至'+this.query_form.end_month+'月费用'

                            this.msg =  '收费日期'+this.query_form.charge_start_date+'至'+this.query_form.charge_end_date+'日，可查询费用期间最大范围  '
                                +this.max_cost_month_start+'至'+this.max_cost_month_end+'月'


                            this.dataList = res.data.list

                        }



                    } else {

                        this.$confirm(res.msg, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {

                        }).catch(() => {

                        });
                    }
                }).catch((err) => {
                    this.loading = false
                })


            },


            closeEvent(e) {
                if (e.type == 'close') {
                    this.dialog_order_info.open = false;
                }
            },

        }
    }
</script>

<style scoped>
    .query-box{
        padding: 15px;
        display: flex;flex-direction: row;
    }
    .base-page{
        width: 100%;height: 100%;
        display: flex;flex-direction: column;
    }

    .base-body{
        flex: 1;width: 100%;
    }

    .base-table{
        padding: 0 15px;
        border-radius: 5px;
        background: #ffffff;
        height: 100%;width: 100%;


    }
    .base-footer{
        text-align: right;
        padding: 5px;
    }

    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand label {
        width: 90px;
        /*color: #99a9bf;*/
    }
    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }

</style>
